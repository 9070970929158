import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const Modal = ({ component: Component, onCloseModal, modalAction, modalActionName, openModal, title, modalProps, modalWidth, data, setOpenConfirmModal, setOpenTransferModal, className, disableBuy, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modal"
      fullWidth={true}
      maxWidth={modalWidth}
    >
      {title && (
        <div className="modalHeader">
          <DialogTitle id="alert-dialog-title" className="modalTitle"
            style={{ flex: 1 }}>
            {title}
          </DialogTitle>
          <DialogTitle id="alert-dialog-title" className="modalClose"
            onClick={onCloseModal}>
            <span className="closeTitle">{t("Fermer")}</span>
          </DialogTitle>
        </div>
      )}

      <DialogContent className={`${className ? ' ' + className : ''}`}>

        <Component componentProps={modalProps} onCloseModal={onCloseModal} setOpenConfirmModal={setOpenConfirmModal}
          setOpenTransferModal={setOpenTransferModal} data={data} />
      </DialogContent>
      {modalActionName && (
        <DialogActions>
          {["close", "fermer"].includes(modalActionName.toLowerCase()) ?
            <Button color="primary" onClick={onCloseModal}>
              {t("Fermer")}
            </Button>
            :
            <Button
              onClick={(event) => modalAction(event, modalProps)}
              className="modalButton"
              style={{
                backgroundColor: modalProps?.participated ?
                  '#3DEC37' :
                  '#2BA7DF',
              }}
              disabled={disableBuy}
            >
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} size={20} />
              ) : (
                modalActionName
              )}

            </Button>
          }
        </DialogActions>
      )}
    </Dialog>
  );
};
Modal.propTypes = {
  onCloseModal: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  modalProps: PropTypes.any,
  modalWidth: PropTypes.any,
  modalAction: PropTypes.any,
  modalActionName: PropTypes.any,
};

export default Modal;
