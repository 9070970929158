import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const ChapterItem = ({ chapter, newChaptersProgress, slug, index }) => {
  const { t } = useTranslation();
  const newProgress =
    newChaptersProgress &&
    newChaptersProgress.length > 0 &&
    newChaptersProgress.find((p) => p?.chapter_id === chapter?.chapter?.id);
  // const newProgressValue =
  //   newProgress && parseInt(chapter?.nbContent) !== 0
  //     ? Math.round((newProgress?.nbCompleted / chapter?.nbContent) * 100)
  //     : 0;

  const newProgressValue =
    newProgress?.progress || 0;
  const handleName = (name) => {
    if (name.includes(',')) {
      const tab2 = [];
      const tab3 = [];
      const tab4 = [];
      const tab1 = name.split(', ');
      tab2.push(tab1.map((e) => e.split(' ').reverse().join(' ')));
      tab3.push(tab2.map((e) => e.map((r) => r.split(' '))));
      tab4.push(
        tab3.map((e) =>
          e.map((r) =>
            r.map(
              (f) =>
                ' ' +
                f[0][0].toUpperCase() +
                '.' +
                (f[2] && f[2] == 'ben' ? f[1] : f[2] ? f[2] : f[1])
            )
          )
        )
      );
      return tab4;
    } else if (name) {
      const tab2 = [];
      const tab3 = [];
      const tab1 = name.split(', ').reverse();
      tab2.push(tab1[0].split(' '));

      tab3.push(
        tab2.map((e) => ' ' + (e[2] ? e[2][0].toUpperCase() : e[1][0].toUpperCase()) + '.' + e[0])
      );

      return tab3;
    } else if (!name.includes(' ')) return name;
  };

  return (
    <Link
      className="chapter-item"
      to={
        chapter?.chapter?.type !== 'recording'
          ? `/subjects/${slug}/chapters/${chapter.chapter.slug}/lessons/${chapter.firstLessonSlug}/content`
          : `/subjects/${slug}/chapters/${chapter.chapter.slug}/recordings/${chapter.firstLessonSlug}/content`
      }
    >
      <span className="number">{index < 9 ? `0${index + 1}` : index + 1}</span>
      <div className="details">
        <span className="label">{chapter.chapter.name}</span>
        <div className="infos">
          <span className="teacher">
            {t('Par')} {handleName(chapter.instructor)}{' '}
          </span>
          {/* <span className="videos">{chapter.nbContent} {t("videos")}</span> */}
          {chapter.nbContent !== 1 && chapter.nbContent <= 10 ? (
            <span className="videos">
              {chapter.nbContent}{' '}
              {chapter?.chapter.type === 'exam' ? t('Magazines') : t('videosAr')}
            </span>
          ) : (
            <span className="videos">
              {chapter.nbContent} {chapter?.chapter.type === 'exam' ? t('Magazines') : t('videos')}
            </span>
          )}
        </div>
        <div className="progress-container">
          <BorderLinearProgress
            variant="determinate"
            value={newProgressValue > 100 ? 100 : newProgressValue}
            className="bg-orange"
          />
          <span className="progress">{newProgressValue > 100 ? 100 : newProgressValue}%</span>
        </div>
      </div>
    </Link>
  );
};

ChapterItem.propTypes = {};

export default ChapterItem;
