export function calculateSubjectProgress(progress, videos) {
    const subjectProgress = new Map();
    const subjectVideoCount = new Map();

    videos?.forEach(({ subject_id, video_id }) => {
        if (!subjectProgress.has(subject_id)) {
            subjectProgress.set(subject_id, 0);
            subjectVideoCount.set(subject_id, 0);
        }

        if (progress?.[video_id]) {
            subjectProgress.set(subject_id, subjectProgress.get(subject_id) + progress[video_id].percentage);
        }
        subjectVideoCount.set(subject_id, subjectVideoCount.get(subject_id) + 1);
    });

    return Array.from(subjectProgress.keys()).map(subject_id => ({
        subject_id: Number(subject_id),
        progress: Number((subjectProgress.get(subject_id) / subjectVideoCount.get(subject_id)).toFixed(2))
    }));
}


export function calculateChapterProgress(progress, videos) {
    const chapterProgress = new Map();
    const chapterVideoCount = new Map();

    videos?.forEach(({ chapter_id, video_id }) => {
        if (!chapterProgress.has(chapter_id)) {
            chapterProgress.set(chapter_id, 0);
            chapterVideoCount.set(chapter_id, 0);
        }

        if (progress?.[video_id]) {
            chapterProgress.set(chapter_id, chapterProgress.get(chapter_id) + progress[video_id].percentage);
        }
        chapterVideoCount.set(chapter_id, chapterVideoCount.get(chapter_id) + 1);
    });
    return Array.from(chapterProgress.keys()).map(chapter_id => ({
        chapter_id: Number(chapter_id),
        progress: Number((chapterProgress.get(chapter_id) / chapterVideoCount.get(chapter_id)).toFixed(2))
    }));
}
