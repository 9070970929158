import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import ChapterItem from "./ChapterItem";


const SubjectTabs = ({ chapters, slug, newChaptersProgress, chapterSections, chaptersProgress }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('course');
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const courses = chapters.filter((chapter) => chapter.chapter.type === 'course');
  const recordings = chapters.filter((chapter) => chapter.chapter.type === 'recording');
  const exams = chapters.filter((chapter) => chapter.chapter.type === 'exam');
  const divers = chapters.filter((chapter) => chapter.chapter.type === 'divers');
  useEffect(() => {
    setSelectedTab('course');
    setSelectedChapters(chapters.filter((chapter) => chapter.chapter.type === 'course'));
    setSelectedSections(chapterSections?.filter((section) => section.type === 'course'))
  }, [chapters, chapterSections]);

  useEffect(() => {
    setSelectedChapters(chapters.filter((chapter) => chapter.chapter.type === selectedTab));
    setSelectedSections(chapterSections?.filter((section) => section.type === selectedTab))

  }, [selectedTab]);

  function renderSkeletons() {
    return (
      <>
        {[1, 2, 3, 4, 5].map(() => {
          return (
            <Skeleton
              variant="rect"
              width={'100%'}
              height={120}
              style={{ marginLeft: '0', marginTop: '20px' }}
            />
          );
        })}
      </>
    );
  }

  return (
    <Card className="ta-card subject-tabs">
      <CardContent>
        <div className="child-full">
          <div className="header">
            <span
              className={selectedTab === 'course' ? 'menu-item active' : 'menu-item'}
              onClick={() => setSelectedTab('course')}
              style={courses.length <= 0 ? { pointerEvents: 'none' } : {}}
            >
              {t("Cours")}
            </span>
            <span
              className={selectedTab === 'exam' ? 'menu-item active' : 'menu-item'}
              onClick={() => setSelectedTab('exam')}
              style={exams.length <= 0 ? { pointerEvents: 'none' } : {}}
            >
              {t("Examens")}
            </span>
            <span
              className={selectedTab === 'recording' ? 'menu-item active' : 'menu-item'}
              onClick={() => setSelectedTab('recording')}
              style={recordings.length <= 0 ? { pointerEvents: 'none' } : {}}
            >
              {t("Enregistrements")}
            </span>
            <span
              className={selectedTab === 'divers' ? 'menu-item active' : 'menu-item'}
              onClick={() => setSelectedTab('divers')}
              style={divers.length <= 0 ? { pointerEvents: 'none' } : {}}
            >
              {t("Divers")}
            </span>
          </div>

          <div className="chapters-container">
            {chapters.length > 0 ? null : renderSkeletons()}
            {selectedChapters &&
              selectedChapters.length > 0 &&
              selectedChapters
                .filter((chapter) => chapter.chapter?.chapter_sections?.id === undefined || !chapter.chapter?.chapter_sections?.id)
                .map((chapter, index) => {
                  return (
                    <ChapterItem
                      chapter={chapter}
                      slug={slug}
                      // newChaptersProgress={newChaptersProgress}
                      newChaptersProgress={chaptersProgress}

                      index={index}
                    />
                  );
                })}
            {selectedSections?.length > 0 && selectedSections?.map((section) => (
              <>
                <div
                  className="chapter-section"
                >
                  <div className="details" style={{ color: 'red' }}>
                    <span className="label">{section?.name}</span>
                  </div>
                </div>
                {(
                  selectedChapters || []).filter((chapter) =>
                    chapter.chapter?.chapter_sections?.id === section?.id)
                  .map((chapter, index) => {
                    return (
                      <ChapterItem
                        chapter={chapter}
                        slug={slug}
                        newChaptersProgress={chaptersProgress}

                        //newChaptersProgress={newChaptersProgress}
                        index={index}
                      />
                    );
                  })}
              </>
            ))}
          </div>

        </div>
      </CardContent>
    </Card>
  );
};

SubjectTabs.propTypes = {};

export default SubjectTabs;
