import React from 'react';
import PropTypes from 'prop-types';
import { LessonContent } from "../../index";

const Index = (props) => {
  const { tab, courses, subjectSlug, chapterSlug, lessonSlug, isTabLevel, newcourseProgress } = props;
  const type = courses?.chapter?.type;

  return (
    <div className="content-table">
      <div className="li-section">
        {(courses?.lessons || [])
          .filter((lesson) => {
            return (parseInt(lesson?.tabId) === tab?.id || lesson?.tabId === tab?.id) && (lesson.sections === undefined || !lesson.sections)
          })
          .map((lesson) => {
            const lessonKey = lesson?.content?.id
            return (
              <LessonContent
                lesson={lesson}
                lessonKey={lessonKey}
                type={type}
                subjectSlug={subjectSlug}
                chapterSlug={chapterSlug}
                lessonSlug={lessonSlug}
                newcourseProgress={newcourseProgress}
              />
            );
          })}

      </div>
      {(courses?.sections || [])
        .filter((section) => section?.tabId === tab.id)
        .sort((a, b) => (a.sectionName < b.sectionName ? 1 : -1))
        .sort((a, b) => (a.sectionPosition > b.sectionPosition ? 1 : -1))
        .map((section) => (
          <div key={`section-${section?.sectionId}`} className="li-section">
            <span key={section?.sectionId} className="header">
              {section?.sectionName}
            </span>
            {(type !== "recording" &&
              courses?.lessons || []).filter((lesson) =>
                lesson.sections === section?.sectionId)
              .map((lesson, index) => {
                const lessonKey = `item-${section?.sectionId}-${lesson}`;
                return (
                  <LessonContent
                    lesson={lesson}
                    lessonKey={lessonKey}
                    type={type}
                    subjectSlug={subjectSlug}
                    chapterSlug={chapterSlug}
                    lessonSlug={lessonSlug}
                    newcourseProgress={newcourseProgress}
                  />
                );
              })}
            {(type === "recording" &&
              courses?.lessons || []).filter((lesson) =>
                lesson.sections === section?.sectionId)
              .sort((a, b) => (a?.content?.created_at < b?.content?.created_at) ? 1 : -1)
              .map((lesson, index) => {
                const lessonKey = `item-${section?.sectionId}-${lesson}`;
                return (
                  <LessonContent
                    lesson={lesson}
                    lessonKey={lessonKey}
                    type={type}
                    subjectSlug={subjectSlug}
                    chapterSlug={chapterSlug}
                    lessonSlug={lessonSlug}
                    newcourseProgress={newcourseProgress}
                  />
                );
              })}
          </div>
        ))}
    </div>
  );
};

Index.propTypes = {
  tab: PropTypes.object,
  courses: PropTypes.object,
  subjectSlug: PropTypes.any,
  chapterSlug: PropTypes.any,
  lessonSlug: PropTypes.any,
};

export default Index;
