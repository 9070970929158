import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ifvisible from 'ifvisible.js';
import { useDispatch, useSelector } from 'react-redux';
import { getNewChapterContentsProgress, saveNewProgress } from "../../../redux/slices/newProgress";
import { getProgress, getRelatedSilverVideos, getSubjects } from '../../../redux/slices/subjects';

const PaidPlayer = (props) => {
  const { courseProgress, newcourseProgress } = props;
  const { subjects, new_subject_progress } = useSelector((state) => state.subjects);
  const { courses, recordings } = useSelector((state) => state.courses);
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.user);
  const { currentLesson, data, chapterId } = props;
  const otp = currentLesson && currentLesson.otp;
  const playbackInfo = currentLesson && currentLesson.playbackInfo;
  const contentId = data && data.id;
  const subjectId = courses ? courses.subject.id : recordings.subject.id;
  const isFree = permissions?.freeHours == null ? false : true;
  const { informations } = useSelector((state) => state.user);
  const intervalIdRef = useRef(null);
  const socketRef = useRef(null);
  const token = localStorage.getItem("token");
  const [videoStartTime, setVideoStartTime] = useState();
  const videoStartTimeRef = useRef(videoStartTime);

  useEffect(() => {
    videoStartTimeRef.current = videoStartTime;
  }, [videoStartTime]);

  useEffect(() => {
    setVideoStartTime(new_subject_progress?.progress?.[contentId]);
  }, [new_subject_progress, contentId]);

  useEffect(() => {
    if (!subjects) {
      dispatch(getSubjects());
      dispatch(getProgress());
      dispatch(getRelatedSilverVideos());
    }
  }, []);


  useEffect(() => {
    let ws;
    let isMounted = true;

    const connectWebSocket = () => {
      if (!isMounted) return; // Stop reconnection if component is unmounted

      ws = new WebSocket(`wss://progressv2.takiacademy.com/ws/progress?content_id=${currentLesson?.content?.id}&token=${token}`);
      socketRef.current = ws;

      ws.onmessage = (message) => {
        console.log("Received WebSocket message:", message.data);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = (event) => {
        console.warn("WebSocket closed:", event.code, event.reason);

        if (isMounted) {
          // Attempt to reconnect after a delay
          setTimeout(() => {
            console.log("Reconnecting WebSocket...");
            connectWebSocket();
          }, 5000);
        }
      };
    };


    connectWebSocket();

    return () => {
      isMounted = false;
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [currentLesson?.content?.id, token]);
  const sendWebSocketMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.warn("WebSocket is not open");
    }
  };

  useEffect(() => {
    if (data && playbackInfo) {
      const video = new window.VdoPlayer({
        otp: otp,
        playbackInfo: playbackInfo,
        theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
        container: document.querySelector('#embedBox'),
        plugins: [{ name: 'keyboard', options: { preset: 'default' } }],
      });

      const handleProgress = () => {
        console.log("Tracking progress... Duration:", video.currentTime);
        sendWebSocketMessage({
          progress: String(Math.round(video.currentTime)),
          state: "playing",
        });
      };

      const startProgressTracking = () => {
        if (intervalIdRef.current === null) {
          intervalIdRef.current = setInterval(handleProgress, 5000);
        }
      };

      const stopProgressTracking = () => {
        if (intervalIdRef.current !== null) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      };

      let hasSeeked = false;

      video.addEventListener("play", () => {
        if (!hasSeeked && videoStartTimeRef.current?.lastWatchedSecond) {
          video.seek(videoStartTimeRef.current.lastWatchedSecond);
          hasSeeked = true;
          videoStartTimeRef.current.lastWatchedSecond = undefined;
        }

        if (video.currentTime === 0) {
          sendWebSocketMessage({
            progress: String(Math.round(0)),
            state: "playing",
          });
        }
        startProgressTracking();
      });

      video.addEventListener("pause", () => {
        console.log("Video paused");
        stopProgressTracking();
        sendWebSocketMessage({
          progress: String(Math.round(video.currentTime)),
          state: "paused",
        });
      });

      video.addEventListener("seeked", () => {
        setTimeout(() => {
          sendWebSocketMessage({
            progress: String(Math.round(video.currentTime)),
            state: "skipped_forward",
          });
        }, 20);
      });
      video.addEventListener('load', () => {
        const duration = video.duration;
        let time = 50;
        let content = 0;
        let completed = 0;
        let completedSaved = false;
        let oldTotalPlayed = 0;
        const saveInterval = setInterval(() => {
          if ((video.totalCovered * 100) / duration >= 80) {
            completed = 1;
          }
          if (completed && !completedSaved) {
            dispatch(
              saveNewProgress({
                viewToken: Math.random().toString(36).substring(2) +
                  Date.now().toString(36),
                contentId: contentId,
                chapterId: parseInt(chapterId),
                completed: completed == 0 ? false : true,
                totalWatched: video.totalCovered,
                watchedTime: time,
                subjectId: subjectId,
                isFree: isFree
              }),
            );
            completedSaved = true;
            clearInterval(saveInterval);
            setTimeout(() => {
              dispatch(getNewChapterContentsProgress(chapterId));
            }, 2000);
          }
          if (video.status === 1) {
            if (video.totalPlayed >= oldTotalPlayed + time) {
              oldTotalPlayed += 50;
              dispatch(
                saveNewProgress({
                  viewToken: Math.random().toString(36).substring(2) +
                    Date.now().toString(36),
                  contentId: contentId,
                  chapterId: parseInt(chapterId),
                  completed: completed == 0 ? false : true,
                  totalWatched: video.totalCovered,
                  watchedTime: time,
                  isFree: isFree,
                  subjectId: subjectId
                }),
              );

            }
          }
        }, 1000);

        ifvisible.on('blur', () => {
          if (video.hasOwnProperty('status') && video.status === 1) {
            video.pause();
          }
        });
        ifvisible.on('wakeup', () => {
          if (video.hasOwnProperty('status') && video.status === 2) {
            video.play();
          }
        });
      });
      return () => {
        stopProgressTracking();
      };
    }
  }, [data, otp, playbackInfo]);

  return (
    <div>
      {data && (
        <div className="rounded">
          <div id="embedBox" className="embed-responsive-item"></div>
        </div>
      )}
    </div>
  );
};

PaidPlayer.propTypes = {
  data: PropTypes.object,
  otp: PropTypes.any,
  playbackInfo: PropTypes.any,
  update: PropTypes.bool,
};

export default PaidPlayer;