import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputCard from '../../components/AuthComponents/InputCard';
import {
  Button,
  Slide,
} from "@material-ui/core";
import cadnat from '../../assets/img/icons/cadnat.svg';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import WindowDimensions from '../../utilities/windowDimenssions';
import { Link } from 'react-router-dom';
import { formatMessages } from '../../utilities/translateHelper';
import { editPassword } from '../../redux/slices/user';
import { enqueueSnackbar } from '../../redux/slices/notifier';
import CreateResetForm from '../../components/ForceUpdatePassword/CreateResetForm';
import SuccessReset from '../../components/ForceUpdatePassword/SuccessReset';
import dayjs from "dayjs";
import ReCAPTCHA from 'react-google-recaptcha';


const Index = () => {
  const { t } = useTranslation();
  const [credential, setCredential] = useState();
  const { width } = WindowDimensions();
  const dispatch = useDispatch();
  const { informations, editPasswordData } = useSelector((state) => state.user);
  const { authResult, success } = useSelector((state) => state.auth);
  const [validationResult, setValidationResult] = useState(authResult);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [validationStatus, setValidationStatus] = useState(success);
  const [forgotClicked, setForgotClicked] = useState(false);
  const [successTrue, setSuccessTrue] = useState(false);
  const recaptchaRef = useRef();

  const [oldPassword, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [new_password_confirmation, setNewPasswordConfirmation] = useState('');

  const maxWidth = width >= 750 ? 750 : width - 50;

  useEffect(() => {
    setValidationResult(authResult);
  }, [authResult]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);

  useEffect(() => {
    if (editPasswordData.status === "succeeded" && editPasswordData.data) {
      setSuccessTrue(true);
    }
  }, [editPasswordData.status]);
  const updateValidation = (oldPassword, newPassword, new_password_confirmation) => {
    if (oldPassword === '' || newPassword === '' || new_password_confirmation === '') {
      return { success: false, 'message': t('Remplir les champs') };
    }
    if (newPassword && !(/^(?=.*\d)(?=.*[A-Z])(?=.*[:=+*-_()&?.!;,@#$%€¥])(?!.*(.)\1{5}).*[a-z]/m).test(newPassword)) {
      return { success: false, 'message': t('The password should contain at least a capital letter a small letter a number and a special character') };
    }
    if (oldPassword.trim().length === 0) {
      return { success: false, 'message': t("This value should not be blank") };
    }
    if (newPassword.length > 16 || newPassword.length < 8) {
      return { success: false, 'message': t('The password value should be between 8 and 16 characters') };
    }
    if (oldPassword.length < 8) {
      return { success: false, 'message': t('The old password is too short It should have 8 characters or more') };
    }
    if (newPassword !== new_password_confirmation) {
      return { success: false, 'message': t('Les nouveaux mot de passes ne sont pas identiques') };
    }
  }

  const handleLinkForgotClick = () => {
    setForgotClicked(true)
  }

  const handleSubmit = () => {
    const validation = updateValidation(oldPassword, newPassword, new_password_confirmation);
    if (validation) {
      dispatch(
        enqueueSnackbar({
          message: validation.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
    else {
      const req = {
        oldPassword,
        newPassword,
        new_password_confirmation,
      };

      recaptchaRef.current
        .execute()
        .then(async (res) => {

          dispatch(editPassword({ ...req, 'g-recaptcha-response': res }));


        })
      setPassword('');
      setNewPassword('');
      setNewPasswordConfirmation('');
    }
  };

  if (!informations) {
    return (
      <div className="login-card" style={{ margin: '50px 0px' }}>
        <div className="centered-box"
          style={{ minWidth: maxWidth, minHeight: 500 }}>
          <Skeleton variant="rect" width={'100%'} height={500} />
        </div>
      </div>
    );
  }
  return (
    <div className="login-card" style={{ margin: '30px 0px' }}>
      {forgotClicked ? (
        <CreateResetForm />
      ) : (successTrue) ?
        (<SuccessReset />) : (
          <div className="centered-box"
            style={{ minHeight: 500 }}>
            {informations && isNaN(credential) && (
              <img src={cadnat}
                width={170}
                style={{ margin: 10 }} />
            )}
            <span className="title-warning">{t("Warning")}</span>
            <span className="text-warning">{t("Your password expired For security reasons we force a password change from time to time Please change it below Thanks")}</span>
            <InputCard
              value={oldPassword}
              handleFunction={(e) => setPassword(e.target.value)}
              placeholder={informations?.passwordChangedAt ? t('Mot de passe actuel mis à jour le') + ' ' + dayjs(informations?.passwordChangedAt).format('DD/MM/YYYY') : t("Mot de passe Actuel")}
              typeInput={'password'}
              name="code"
            />
            <InputCard
              value={newPassword}
              handleFunction={(e) => setNewPassword(e.target.value)}
              placeholder={t("Nouveau Mot de passe")}
              typeInput={'password'}
              name="code"
            />
            <InputCard
              value={new_password_confirmation}
              handleFunction={(e) => setNewPasswordConfirmation(e.target.value)}
              placeholder={t("Confirmez le mot de passe")}
              typeInput={'password'}
              name="code"
            />
            <Link to={{ pathname: "update-password", state: window.location.href.split("?")[1] }} onClick={handleLinkForgotClick} className="reset-password-warning">
              {t("MOT DE PASSE OUBLIÉ")}
            </Link>
            <Button
              style={{ width: width < 750 && '100%' }}
              variant="outlined"
              disableElevation
              onClick={(e) => handleSubmit(e)}
              className="submit-reset"
            >
              {t("Terminer")}
            </Button>
          </div>)}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={validationStatus ? 'success' : 'error'}
          onClose={() => setOpenSnackBar(false)}
        >
          {t(formatMessages(validationResult))}
        </Alert>
      </Snackbar>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        size="invisible"
      />
    </div>
  );
};
export default Index;
