import React from 'react';
import { useTranslation } from 'react-i18next';
import BlueButton from '../../BlueButton';
import Typography from '@material-ui/core/Typography';
import AttachmentsSession from './AttachmentsSession';
import parse from 'html-react-parser';

const Index = (props) => {
  const { i18n, t } = useTranslation();
  const { name, description, teacher, subject, levels, files, event } = props.componentProps;
  return (
    <div className={i18n.language === 'ar' ? 'sessionModal rtl' : 'sessionModal'}>
      <BlueButton text={subject.name} width={subject.name.length * 7 >= 60 ? subject?.name?.length * 7 : 70} height={'100%'} fontSize={10} />
      {/* <BlueButton text={subject?.name} height={'20%'} fontSize={12} /> */}
      <Typography variant="h6" className="sessionDescription">
        {description?.length ? parse(description) : name}
      </Typography>
      <div className="modalLine"></div>
      <div className="sessionInfo">
        <Typography variant="h8" className="infoSpan">
          {t(" Instructeur")}
          <Typography variant="h8" className="infoValue">
            {teacher}
          </Typography>
        </Typography>
        <Typography variant="h8" className="infoSpan">
          {t(" Niveaux")}
          <Typography variant="h8" className="levels">
            {t(levels)}
          </Typography>
        </Typography>
      </div>
      <Typography variant="h8" className="infoSpan">
        {t(" heure")}
        <Typography variant="h8" className="infoValue">
          {event?.start_time}
        </Typography>
      </Typography>
      <div className="sessionInfo">
        <Typography variant="h8" className="infoSpan">
          {`${t("Description")} :`}
          <Typography variant="h8" className="description">
            {event?.description}
          </Typography>
        </Typography>
      </div>
      <div className="sessionDocuments">
        {event?.files?.length === true && (
          <Typography variant="h7" className="sessionDescription">
            {t("Travail à faire")}
          </Typography>
        )}
        {event?.files?.map((file) => {
          return <AttachmentsSession file={file} />;
        })}
      </div>


    </div>
  );
};

export default Index;
