import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputCard from '../../components/AuthComponents/InputCard';
import Button from '@material-ui/core/Button';
import VerifiedSms from '../../../src/assets/img/verified-sms.png';
import Verification from '../../../src/assets/img/verification.png';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { confirmCode, resendConfirmCode } from '../../redux/slices/auth';
import { me } from '../../redux/slices/user';
import Skeleton from '@material-ui/lab/Skeleton';
import WindowDimensions from '../../utilities/windowDimenssions';
import { formatMessages } from '../../utilities/translateHelper';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const Index = () => {
  const { t } = useTranslation();
  const [credential, setCredential] = useState();
  const { width } = WindowDimensions();
  const dispatch = useDispatch();
  const recaptchaRef = useRef();

  const { informations } = useSelector((state) => state.user);
  const { authResult, success } = useSelector((state) => state.auth);
  const [validationResult, setValidationResult] = useState(authResult);
  const [values, setValues] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [validationStatus, setValidationStatus] = useState(success);
  const location = useLocation();
  window.history.pushState(null, null, location.state !== undefined ? '?' + location.state : '');
  const previousUrl = document.referrer;
  // if (previousUrl.includes('?nextPage=offers')) {
  //   const url = new URL(window.location);
  //   url.searchParams.set('nextPage', 'offers');
  //   window.history.pushState({}, '', url);
  // }
  const maxWidth = width >= 750 ? 750 : width - 50;
  useEffect(() => {
    dispatch(me());
  }, [dispatch]);
  useEffect(() => {
    setValidationResult(authResult);
  }, [authResult]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);
  useEffect(() => {
    localStorage.getItem('credential');
    setCredential(
      localStorage.getItem('credential')
        ? localStorage.getItem('credential')
        : informations?.country_id === 1
          ? informations?.phone
          : informations?.email
    );
  }, [credential]);
  const handleChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const resendCode = () => {
    localStorage.setItem('resend', true);
    dispatch(resendConfirmCode());
    setOpenSnackBar(true);
  };
  const handleSubmit = async () => {
    if (
      !values.code ||
      values.code.length !== 6 ||
      !/^[a-zA-Z0-9]+$/.test(values.code)
    ) {
      setValidationStatus(false);
      setValidationResult(t('Code invalid'));
      return setOpenSnackBar(true);
    }
    if (+informations.verified) {
      setValidationStatus(false);
      setValidationResult(t('You are verified'));
      return setOpenSnackBar(true);
    }
    const data = {
      'code': values.code,
      'credential': credential,
    };
    recaptchaRef.current
      .execute()
      .then(async (res) => {
        await dispatch(confirmCode({ data: { 'g-recaptcha-response': res }, code: values?.code }))

          .then(async (response) => {

          })
          .catch((error) => console.log(error));
      })
    return setOpenSnackBar(true);
  };
  if (!informations) {
    return (
      <div className="login-card" style={{ margin: '50px 0px' }}>
        <div className="centered-box" style={{ minWidth: maxWidth, minHeight: 500 }}>
          <Skeleton variant="rect" width={'100%'} height={500} />
        </div>
      </div>
    );
  }

  return (
    <div className="login-card" style={{ margin: '50px 0px' }}>
      <div className="centered-box" style={{ minHeight: 500 }}>
        <span className="title">{t('Dernière étape')}</span>
        {informations && isNaN(credential) && (
          <img
            src={Verification}
            width={width >= 750 ? 350 : maxWidth - 100}
            style={{ margin: 10 }}
          />
        )}
        {informations && !isNaN(credential) && (
          <img
            src={VerifiedSms}
            width={width >= 750 ? 350 : maxWidth - 100}
            style={{ margin: 10 }}
          />
        )}
        <InputCard
          value={values.code}
          handleFunction={handleChange}
          placeholder={t('Code de verification')}
          typeInput={'text'}
          icon={
            !isNaN(credential) ? (
              <MobileScreenShareIcon className="icon-input" />
            ) : (
              <MailOutlineIcon className="icon-input" />
            )
          }
          name="code"
        />
        <span className="card-description">
          {t('Veuillez attendre le message et saisir le code reçu au')}:{' '}
          {informations?.phone || informations?.email} {t('et cliquer sur le bouton Terminer')}
        </span>
        {!localStorage.getItem('resend') && (
          <span className="resend-code" onClick={resendCode}>
            {t('renvoyer le code de vérification')}
          </span>
        )}
        <Button
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-login"
        >
          {t('Terminer')}
        </Button>
      </div>
      {validationResult !== null && (
        <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'Bottom',
            horizontal: 'center',
          }}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            severity={validationStatus ? 'success' : 'error'}
            onClose={() => setOpenSnackBar(false)}
          >
            {t(formatMessages(validationResult))}
          </Alert>
        </Snackbar>
      )}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        size="invisible"
      />
    </div>
  );
};
export default Index;
