import { useTranslation } from 'react-i18next';
import React from 'react';

import PropTypes from 'prop-types';
import { DialogTitle } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Index = ({ url, open, setOpen, title }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const renderToolbar = (Toolbar) => {
    return (
      <Toolbar>
        {(slots) => {
          const { CurrentPageInput, Download, EnterFullScreen, NumberOfPages } = slots;
          return (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <div style={{ padding: '0px 2px' }}>
                <CurrentPageInput />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <span style={{ marginRight: '4px', marginLeft: '4px' }}> /</span>
                <NumberOfPages />
              </div>
              <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                <EnterFullScreen />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Download />
              </div>
            </div>
          );
        }}
      </Toolbar>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="pdfPreview"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">{title.replace(/\.pdf$/, '')}</span>
        <span className="close-btn" onClick={handleClose}>
          {t('Fermer')}
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <div
          style={{
            height: '95%',
            padding: '10px',
            borderRadius: '23.831px',
            background: 'white',
          }}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        </div>
      </DialogContent>
    </Dialog>
  );
};

Index.propTypes = {
  onCloseModal: PropTypes.func,
};

export default Index;
