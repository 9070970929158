import React from 'react';
import { MyProgressItem } from '../../components';
import PropTypes from 'prop-types';

const Index = ({ subjects,

  newSubjectsProgress, subjectProgress }) => {
  return (
    <div className="my-progress-list">
      {subjects &&
        subjects.length > 0 &&
        subjects.map((subject, index) => {
          //old progress
          /* const subjectProgress =
             subjectsProgress &&
             subjectsProgress.length > 0 &&
             subjectsProgress.find((p) => p.subject_id == subject.id);
           const progress = subjectProgress ? subjectProgress.value : 0;*/
          //new progress
          const newProgress =
            newSubjectsProgress &&
            newSubjectsProgress.length > 0 &&
            newSubjectsProgress.find((p) => p.subject_id === subject.id);
          const newSubjectProgress = (newProgress && subject?.subjectVideos !== 0) ? Math.round((newProgress?.nbCompleted / subject?.subjectVideos) * 100) : 0;
          return <MyProgressItem data={subject} key={index}
            //subjectProgress={progress}
            newProgress={subjectProgress?.find(
              (sub) => sub?.subject_id === subject?.id
            )?.progress > 100 ? 100 : subjectProgress?.find(
              (sub) => sub?.subject_id === subject?.id
            )?.progress || 0} />;

        })}
    </div>
  );
};

Index.propTypes = {
  data: PropTypes.array,
  subjects: PropTypes.array,
};

export default Index;
