import React from 'react';
import { NavLink } from "react-router-dom";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { sliceLongStrings } from "../../../utilities/methods";
import getLessonDuration from "../../../utilities/getLessonDuration";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useSelector } from 'react-redux';
const Index = (props) => {
  const { lesson, type, subjectSlug, chapterSlug, lessonSlug, newcourseProgress, lessonKey } = props;
  const isCompleted =
    newcourseProgress &&
    newcourseProgress.length > 0 &&
    newcourseProgress.find((p) => p.content_id === lesson?.content?.id);
  const lessonCompleted = isCompleted ? isCompleted.completed : false;
  const { new_subject_progress } = useSelector((state) => state.subjects);
  console.log("asddsadas", new_subject_progress?.progress?.[lesson?.content?.id])
  console.log("asddlessonsadas", lesson)

  return (
    <>
      <NavLink
        to={type !== 'recording' ?
          `/subjects/${subjectSlug}/chapters/${chapterSlug}/lessons/${lesson?.content?.slug}/content` :
          `/subjects/${subjectSlug}/chapters/${chapterSlug}/recordings/${lesson?.content?.slug}/content`
        }
        key={lesson?.content?.id}
        id={`lesson-${lesson?.content?.id}`}
      >
        <div key={lessonKey} className="liStyle">
          <span className="li-icon">
            {lesson?.lockedVideo ? (
              <LockRoundedIcon className="iconStyle" />
            ) : (
              <>
                {lessonCompleted && <CheckRoundedIcon className="iconStyle" />}
                {lesson?.content?.slug === lessonSlug && !lessonCompleted && (
                  <PlayCircleOutlineRoundedIcon className="play" />
                )}
                {!lessonCompleted && lesson?.content?.slug !== lessonSlug && (
                  <LockOpenIcon className="iconStyle" />
                )}
              </>
            )}
          </span>
          <div className="li-texts" style={lesson?.content?.slug !== lessonSlug ? { color: '#ffffff' } : { color: '#2ba7df', fontWeight: 'bold' }}>
            <span className={lessonCompleted ? 'li-completed' : ''} title={lesson.contentName}>
              {sliceLongStrings(lesson.contentName, 56)}
            </span>

            <span className={lessonCompleted ? 'li-completed' : ''}>
              {lesson?.content?.type.id == '1' ||
                lesson?.content?.type.id == '2' ||
                lesson?.content?.type.id == '6'
                ? lesson.content?.content_videos ? getLessonDuration(lesson?.content?.content_videos[0]?.duration) : 0
                : null
              }
            </span>
          </div>
          {new_subject_progress?.progress?.[lesson?.content?.id]?.percentage > 95 && <DoneAllIcon sx={{ fill: "white" }} />
          }

        </div>
      </NavLink>
    </>
  );
};

export default Index;
