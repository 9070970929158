import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import { CardTitle, MyProgressList } from '../../../components';
import { getNewSubjectsProgress } from "../../../redux/slices/newProgress";

const SubjectWidget = ({ subjectProgress, subject, subjects, totalChapters, totalVideos, newsubjectProgress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newsubjectsProgress } = useSelector((state) => state.newProgress);
  //const [Subjects, setSubjects] = useState([]);
  const [itemsNumber, setItemsNumber] = useState(5);
  const currentProgress = newsubjectProgress ? Math.round((newsubjectProgress.nbCompleted / totalVideos) * 100) : '';
  //const collapsedSubjects= subjects.filter((sub) => sub.id !== subject.id)
  const collapsedSubjects = useMemo(() => {
    return subjects.filter((sub) => sub.id !== subject.id);
  }, [subjects, subject?.id]);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

  const getSubjectsProgress = useCallback(() => dispatch(getNewSubjectsProgress()), [newsubjectProgress]);

  const updateItemsNumber = useCallback(() => setItemsNumber(itemsNumber === 5 && subjects.length > 0 ? subjects && subjects.length : 5),
    [itemsNumber, subjects]);
  const Subjects = useMemo(() => {
    return collapsedSubjects.slice(0, itemsNumber);
  }, [subjects, collapsedSubjects, itemsNumber]);

  useEffect(() => {
    if (!newsubjectsProgress) {
      getSubjectsProgress();
      //dispatch(getNewSubjectsProgress());
    }
  }, []);

  /*
    useEffect(() => {
      if (!newsubjectsProgress) {
        dispatch(getNewSubjectsProgress());
      }
    }, []);

    useEffect(() => {
      if (subjects) {
        setSubjects(collapsedSubjects.slice(0, itemsNumber));
      }
    }, [subjects, collapsedSubjects]);
  */

  /*  useEffect(() => {
      if (subjects) {
        setSubjects(collapsedSubjects.slice(0, itemsNumber));
      }
    }, [itemsNumber]);*/
  const handleItemNumberChange = useCallback(() => setItemsNumber(itemsNumber === 5 ? 5 : subjects.length), [itemsNumber]);

  function renderSkeletons() {
    return (
      <>
        {[1, 2, 3, 4, 5].map(() => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Skeleton variant="rect" width={20} height={20} style={{ marginLeft: '0' }} />
                <Skeleton variant="rect" width={60} height={8} style={{ marginLeft: '12px' }} />
              </div>
              <Skeleton
                variant="rect"
                width={140}
                height={8}
                style={{ marginLeft: '0', marginTop: '8px' }}
              />
            </>
          );
        })}
      </>
    );
  }

  return (
    <Card className="ta-card subject-widget">
      <CardContent>
        <div className={itemsNumber === 5 ? 'child-full' : 'child-full active'}>
          <div className="subject-title">
            {subject ? (
              <>
                <img alt="subject" src={subject?.img} />
                <span>{subject?.label}</span>
              </>
            ) : (
              <>
                <Skeleton variant="rect" width={60} height={60} style={{ marginLeft: '0' }} />
                <Skeleton variant="rect" width={150} height={20} />
              </>
            )}
          </div>
          <div className="progression">
            <div className="textes">
              {subject ? (
                <>
                  <span>{t('Progression')}</span>
                  <span>{subjectProgress?.find(
                    (sub) => sub?.subject_id === subject?.id
                  )?.progress > 100 ? 100 : subjectProgress?.find(
                    (sub) => sub?.subject_id === subject?.id
                  )?.progress}%</span>
                </>
              ) : (
                <>
                  <Skeleton variant="rect" width={40} height={20} style={{ marginLeft: '0' }} />
                  <Skeleton variant="rect" width={60} height={20} />
                </>
              )}
            </div>
            {subjects.length > 0 ? (
              <>
                <BorderLinearProgress variant="determinate" value={subjectProgress?.find(
                  (sub) => sub?.subject_id === subject?.id
                )?.progress > 100 ? 100 : subjectProgress?.find(
                  (sub) => sub?.subject_id === subject?.id
                )?.progress} className="bg-orange" />
              </>
            ) : (
              <Skeleton variant="rect" width={'100%'} height={8} style={{ marginLeft: '0' }} />
            )}
          </div>
          <div className="subject-infos">
            {totalChapters ? (
              <span>
                {t('Nombre de chapitres')}:{' '}
                {totalVideos !== 1 && totalVideos <= 10 ? (
                  <span>
                    {totalChapters} {t('chapitresAr')}
                  </span>) : (
                  <span>
                    {totalChapters} {t('chapitres')}
                  </span>)}
              </span>
            ) : (
              <Skeleton variant="rect" width={160} height={8} style={{ marginLeft: '0' }} />
            )}

            {totalVideos ? (
              <span>
                {t('Nombre de videos')}:{' '}
                {totalVideos !== 1 && totalVideos <= 10 ? (
                  <span>
                    {totalVideos} {t('videosAr')}
                  </span>) : (
                  <span>
                    {totalVideos} {t('videos')}
                  </span>)}
              </span>
            ) : (
              <Skeleton variant="rect" width={140} height={8} style={{ marginLeft: '0' }} />
            )}
          </div>
          <div className="others">
            <CardTitle title={t('autres matieres')} />
            {subject ? (
              <MyProgressList subjects={Subjects}
                subjectProgress={subjectProgress}
                newSubjectsProgress={newsubjectsProgress}
              />
            ) : (
              renderSkeletons()
            )}

            <div
              className="show-more-link"
              //onClick={() => setItemsNumber(itemsNumber === 5 ? subjects && subjects.length : 5)}
              onClick={updateItemsNumber}
            >
              <span>{itemsNumber === 5 ? t('voir plus') : t('voir moins')} </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

SubjectWidget.propTypes = {};

export default SubjectWidget;
